import React from 'react'
import cx from 'classnames'

type NavbarLinkProps = {
  isActive: boolean
  className?: string
  children: React.ReactNode
  [props: string]: unknown
}

export default function NavbarLink(props: NavbarLinkProps) {
  const { isActive, className, children, ...restProps } = props

  return (
    <p
      className={cx(
        'inline-block cursor-pointer whitespace-nowrap border-b-2 pb-1 text-xs font-semibold text-pencatar-primary hover:opacity-100 xl:text-sm',
        isActive
          ? 'border-pencatar-secondary'
          : 'border-transparent opacity-60',
        className,
      )}
      {...restProps}
    >
      {children}
    </p>
  )
}

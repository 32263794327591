/* eslint-disable prettier/prettier */
import React, { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { Drawer, Space, Modal, Button } from 'antd'
import MenuOutlined from '@ant-design/icons/MenuOutlined'
import useUserProfile from 'data/user/useUserProfile'
import stringify from 'qs/lib/stringify'
import userRoutes from 'layouts/routes/user'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import ContentSection from 'components/ContentSection'
import NavbarLink from 'layouts/containers/User/Header/NavbarLink'
import useLocalStorage from 'hooks/useLocalStorage'
import { useMutation } from 'react-query'
import ApiCall from 'services/ApiCall'
import isEmpty from 'lodash/isEmpty'
import { CloseOutlined } from '@ant-design/icons'

export default function UserHeader() {
  const router = useRouter()
  const screens = useBreakpoint()
  const [userToken,
    // setUserToken
  ] = useLocalStorage('userToken')
  // const [, setUserdata] = useLocalStorage('userdata')
  const fetchProfile = useUserProfile()
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  const reqLogout = useMutation((data: string) => {
    return ApiCall.userApi.post('/logout', data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
  })

  const NavbarLinks = useMemo(
    () =>
      userRoutes.map((userRoute) => {
        if (!isEmpty(userRoute.name)) {
          return (
            <NavbarLink
              key={userRoute.name}
              isActive={userRoute.path === router.pathname}
              onClick={() => {
                router.push(userRoute.path)
                setVisibleDrawer(false)
              }}
            >
              {userRoute.name}
            </NavbarLink>
          )
        }
        return undefined
      }),
    [router.pathname],
  )

  const BtnLogout = (typeof window !== 'undefined' && localStorage.getItem('userToken')) && (
    <Button
      type='primary'
      shape='round'
      className='!flex !h-auto !items-center'
      onClick={() => {
        setVisibleDrawer(false)

        Modal.confirm({
          content: 'Yakin ingin keluar?',
          onOk: async () => {
            try {
              fetchProfile.remove()

              await reqLogout.mutateAsync(
                stringify({ UserId: fetchProfile.data?.id }),
              )
            } catch (error) {
              const errorMessage = error?.response?.data?.message

              if (errorMessage) {
                Modal.error({ content: errorMessage })
                return
              }

              Modal.error({ content: 'Unknown Error!' })
            } finally {
              localStorage.removeItem('userToken')
              localStorage.removeItem('userdata')
              // setUserToken(null)
              // setUserdata(null)
              router.reload()
            }
          },
        })
      }}
    >
      Logout
    </Button>
  )

  return (
    <div
      className='relative z-10 bg-white py-6'
      style={{ boxShadow: '0px 8px 16px rgb(0 0 0 / 10%)' }}
    >
      <ContentSection className='flex justify-between'>
        <div className='flex items-center'>
          <img alt='logo' src='/images/logo.png' className='w-8 xl:w-10' />

          <div className='ml-4'>
            <p className='m-0 text-base font-bold text-pencatar-primary xl:text-lg'>
              SIPENCATAR
            </p>
            <p className='m-0 text-[10px] font-semibold text-pencatar-primary xl:text-xs'>
              Kementerian Perhubungan Republik Indonesia
            </p>
          </div>
        </div>

        {screens.lg ? (
          <Space size={screens.xl ? 24 : 16} style={{ marginLeft: 16 }}>
            {NavbarLinks}
            {BtnLogout}
          </Space>
        ) : (
          <>
            <MenuOutlined
              className='ml-4 flex cursor-pointer items-center rounded-md border border-pencatar-primary px-4 text-lg text-pencatar-primary active:opacity-60'
              onClick={() => setVisibleDrawer(true)}
            />

            <Drawer
              open={visibleDrawer}
              headerStyle={{ display: 'none' }}
              contentWrapperStyle={{ maxWidth: '70%' }}
              onClose={() => setVisibleDrawer(false)}
            >
              <div className='mb-5 -mt-3 -mr-3 text-right'>
                <CloseOutlined
                  className='p-3 text-base text-pencatar-primary active:opacity-60'
                  onClick={() => setVisibleDrawer(false)}
                />
              </div>

              <Space size={24} direction='vertical'>
                {NavbarLinks}
                {BtnLogout}
              </Space>
            </Drawer>
          </>
        )}
      </ContentSection>
    </div>
  )
}

import React from 'react'
import UserHeader from 'layouts/containers/User/Header'
import UserFooter from 'layouts/containers/User/Footer'

function UserContainer(props: any) {
  const { Component } = props

  return (
    <>
      <UserHeader />

      <Component {...props} />

      <UserFooter />
    </>
  )
}

export default UserContainer

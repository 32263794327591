import React from 'react'
import dayjs from 'dayjs'
import ContentSection from 'components/ContentSection'

export default function UserFooter() {
  return (
    <ContentSection>
      <p className='inline-block w-full py-4 text-center text-sm font-bold text-pencatar-primary opacity-25'>{`© ${dayjs
        .tz(new Date())
        .format('YYYY')} Kementerian Perhubungan Republik Indonesia`}</p>
    </ContentSection>
  )
}
